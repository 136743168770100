
import { Options, Vue } from "vue-class-component";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Menus from "./components/Menus.vue";

@Options({
  components: {
    Header,
    Footer,
    Menus
  },
})

export default class App extends Vue {}
