
import { Vue } from "vue-class-component";

// @Options({
//   props: {
//     msg: String,
//   },
// })
export default class Header extends Vue {}

