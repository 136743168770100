import { createApp } from "vue";
import App from "./App.vue";
import '@/assets/styles/global.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faFacebookSquare, faTwitter, faInstagram)

createApp(App).component("font-awesome-icon", FontAwesomeIcon).mount("#app");